import { isDLAIDisclaimerAcceptedKey, isDLAIKey } from "./const-keys"
import Cookies from 'js-cookie';

export const isDLAI = () => {
    return (Cookies.get(isDLAIKey) ?? '') == 'true'
}

export const setIsDLAI = () => {
    Cookies.set(isDLAIKey, 'true', { expires: 365 * 100 }) 
}

export const isDLAIDisclaimerAccepted = () => {
    return (Cookies.get(isDLAIDisclaimerAcceptedKey) ?? '') == 'true'
}

export const setIsDLAIDisclaimerAccepted = () => {
    Cookies.set(isDLAIDisclaimerAcceptedKey, 'true', { expires: 365 * 100 })
}