import { useCookies } from "react-cookie";
import { authDataKey, isDLAIKey } from "../../app/const-keys";
import IntroScreenComponent from "./IntroScreenComponent";
import { isBrowser } from "react-device-detect";
import WelcomeFlowBrowserComponent from "./WelcomeFlowBrowserComponent";
import WelcomeFlowMobileComponent from "./WelcomeFlowMobileComponent";
import { useSearchParams } from "react-router-dom";
import { isDLAI } from "../../app/settings";

const FirstScreensComponent = ({ onStartSession }: {
    onStartSession: () => void
}) => {
    const [cookies] = useCookies<string>([authDataKey]);

    const authJson = cookies[authDataKey]
    const isAuthed = authJson != null

    return (
        isAuthed ? <IntroScreenComponent onStartSession={onStartSession} /> : <WelcomeScreenComponent />
    )
}

export const WelcomeScreenComponent = () => {
    const [cookies] = useCookies<string>([isDLAIKey]);
    const isDLAIOn = cookies[isDLAIKey]
    if (isDLAIOn) {
        return <div className='flex flex-col items-center justify-center h-full text-gray-200'>
            Signing in to deeplearning.ai
        </div>
    } else {
        return isBrowser ? <WelcomeFlowBrowserComponent /> : <WelcomeFlowMobileComponent />
    }
}

export default FirstScreensComponent;