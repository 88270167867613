import { Action, Dispatch } from "@reduxjs/toolkit"
import { fetchHistory, fetchSuggestedTopics } from "../api"
import { setCurrentAvatarConfig, setСurrentSuggestedTopics, setMessages, setLoadingMoreMessages } from "./defaultSlice"
import { RootState } from "./store"
import { AvatarConfig, Message } from "../types"

interface MessageBatch {
    id: number
    question: string
    response: string
    created_at: string
    chat_type: 'text' | 'service' | unknown
    meta_message: {
        call_status?: 'started' | 'ended'
        duration_seconds?: number
    }
}

export const getHistory: any = (
    avatarId: number,
    params?: {
        offset?: number,
        reseting?: boolean,
    },
) => {
    return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
        dispatch(setLoadingMoreMessages(true))

        const state = getState().default
        const existingMessages = [...(state.messages ?? [])]

        const history: { items: [MessageBatch] } = await fetchHistory(avatarId, params?.offset)

        let messages: Message[] = []

        for (var i = history.items.length - 1; i >= 0; i--) {
            const messageBatch = history.items[i]

            switch (messageBatch.chat_type) {
                case 'text':
                case 'voice':
                    const userMsg: Message = {
                        id: `user-${messageBatch.id}`,
                        isUser: true,
                        text: messageBatch.question,
                        mode: 'text'
                    }

                    const botMsg: Message = {
                        id: `bot-${messageBatch.id}`,
                        isUser: false,
                        text: messageBatch.response,
                        mode: 'text'
                    }

                    if (userMsg.text) {
                        messages.push(userMsg)
                    }
                    messages.push(botMsg)
                    break

                case 'service':
                    const callStatus = messageBatch.meta_message.call_status
                    const serviceMessage: Message = {
                        id: `service-${messageBatch.id}-${callStatus}`,
                        isUser: false,
                        text: messageBatch.response,
                        mode: callStatus == 'started' ? 'call_started' : 'call_ended',
                        callDuration: messageBatch.meta_message.duration_seconds
                    }
                    messages.push(serviceMessage)
                    break

                default:
                    break
            }
        }

        if (params?.reseting) {
            dispatch(setMessages([]))
            dispatch(setMessages(messages))

        } else {
            const combinedMessages = [...messages, ...existingMessages]
            dispatch(setMessages(combinedMessages))
        }

        dispatch(setLoadingMoreMessages(false))
    }
}


export const setCurrentAvatarConfigAndGetSuggestedTopics: any = (avatarConfig: AvatarConfig) => {
    return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
        dispatch(setCurrentAvatarConfig(avatarConfig))

        const suggestedTopics = await fetchSuggestedTopics(avatarConfig.id)
        dispatch(setСurrentSuggestedTopics(suggestedTopics))
    }
}
