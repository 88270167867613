import { forwardRef, useEffect, useRef, useState, Ref, useImperativeHandle } from "react"
import { AvatarConfig, Message } from "../../app/types"
import { formatCallTime, scrollToBottomSmoothly } from "../../app/utils"
import { generateUUID } from "../../app/webrtc/utils"
import TypingIndicator from "./TypingIndicator"
import { useDispatch, useSelector } from "react-redux"
import { getHistory } from "../../app/redux/actions"
import { selectIsLoadingMoreMessages, selectIsVoiceMode, selectMessages, setLastMessageToSend, setMessages as setMessagesAction } from "../../app/redux/defaultSlice"
import VKComponent from "./VKComponent"
import { isBrowser } from "react-device-detect"

const scrolledToTopThreshold = 20
const messagesGap = 10

export interface ChatHistoryComponentInterface {
    addNewMessage: (msg: string, isUser: boolean) => void
}

const ChatHistoryComponent = forwardRef(({ avatarConfig, isThinking, historyContainerRef, isVKOn, setIsVKOn }: {
    avatarConfig: AvatarConfig | null,
    isThinking: boolean,
    historyContainerRef: any,
    isVKOn: boolean,
    setIsVKOn: (on: boolean) => void
}, ref: Ref<ChatHistoryComponentInterface>) => {
    useImperativeHandle(ref, () => ({
        addNewMessage: (text: string, isUser: boolean) => {
            addMessage({
                id: generateUUID(),
                text: text,
                isUser: isUser,
                mode: 'text'
            })

            setTimeout(() => {
                scrollToBottomSmoothly(historyContainerRef.current!, { smooth: true })
            }, 10)
        }
    }))

    const dispatch = useDispatch()

    const messages = useSelector(selectMessages) ?? []
    const addMessage = (newMsg: Message) => {
        dispatch(setMessagesAction([...(messages ?? []), newMsg]))
    }
    const areMessages = (messages.length > 0)
    useEffect(() => {
        scrollToBottomSmoothly(historyContainerRef.current!, { smooth: false })
    }, [areMessages])

    const isLoadingMoreMessages = useSelector(selectIsLoadingMoreMessages)

    const loadMoreMessages = () => {
        const avatarId = avatarConfig?.id
        if (avatarId) {
            const offsetByMessages = messages.length
            const backendOffset = Math.ceil(offsetByMessages / 2)
            dispatch(getHistory(avatarId, { offset: backendOffset }))
        } else {
            console.error(`avatar isn't available`)
        }
    }

    const distToBottomRef = useRef(0)
    useEffect(() => {
        const onScroll = () => {
            distToBottomRef.current = historyContainerRef.current?.scrollHeight - historyContainerRef.current?.scrollTop

            const scrollTop = historyContainerRef.current?.scrollTop

            if (scrollTop < scrolledToTopThreshold && !isLoadingMoreMessages && messages.length > 0) {
                loadMoreMessages()
            }
        }
        historyContainerRef.current.addEventListener('scroll', onScroll)

        return () => historyContainerRef.current?.removeEventListener('scroll', onScroll)
    }, [messages])

    const isVoiceMode = useSelector(selectIsVoiceMode)

    useEffect(() => {
        const avatarId = avatarConfig?.id
        if (avatarId) {
            dispatch(getHistory(avatarId, { reseting: true }))
        }

    }, [avatarConfig])

    useEffect(() => {
        const avatarId = avatarConfig?.id
        let timeoutId: any
        if (avatarId && !isVoiceMode) {
            timeoutId = setTimeout(() => {
                dispatch(getHistory(avatarId, { reseting: true }))
            }, 3000)
        }

        return () => clearTimeout(timeoutId)

    }, [avatarConfig, isVoiceMode])

    const oldestMessageIdRef = useRef('')
    useEffect(() => {
        // check if it's adding before oldest ones, then we need to preserve content position
        if (messages?.length > 0 && oldestMessageIdRef.current != '') {
            if (messages[0].id != oldestMessageIdRef.current) {
                // scroll for old history insert (like didn't move)
                const element = historyContainerRef.current
                element.scroll({
                    top: historyContainerRef.current?.scrollHeight - distToBottomRef.current,
                    left: 0,
                    behavior: 'instant',
                });
            }
        }

        if (messages?.length > 0) {
            oldestMessageIdRef.current = messages[0].id
        }
    }, [messages])

    return (
        <div
            ref={historyContainerRef}
            className='px-[4px] pt-[32px] pb-[2px] h-full w-full flex flex-col overflow-auto no-scrollbar'
            style={{
                gap: messagesGap,
                maskImage: 'linear-gradient(to bottom, #000 calc(100% - 70px), transparent calc(100% - 20px))',
            }}>

            {messages.map((msg: Message) => <MessageComponent key={msg.id} msg={msg} />)}

            <TypingIndicator style={{
                ...msgStyle({ isUser: false }),
                opacity: isThinking ? 1 : 0,
                transition: 'opacity 0.4s ease',
            }} />
            <div style={{ height: 5 }} />

            {messages.length > 0 && <VKComponent
                shown={isVKOn && !isThinking}
                onSelect={(text: string) => {
                    dispatch(setLastMessageToSend({
                        text,
                        inputType: 'text'
                    }))
                    setIsVKOn(false)
                }} />}
        </div>
    )
});

const MessageComponent = ({ msg }: { msg: Message }) => {

    const call = (type: 'ended' | 'started') => {
        const callDuration = msg.callDuration ?? 0

        return <div style={{
            background: 'white',
            borderRadius: 16,
            width: 340,
            minHeight: type == 'ended' ? 74 : 44,
            padding: '10px 16px',
            display: 'flex',
            alignSelf: 'flex-end',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }} className=''>

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ color: 'black', fontWeight: 500, alignSelf: 'flex-start' }}>
                    Voice call {type == 'ended' ? 'ended' : 'started'}
                </div>

                <div style={{ marginTop: 2 }}>
                    <img src='images/call-end.svg' />
                </div>
            </div>

            {type == 'ended' && <div style={{ color: '#828387' }}>
                {(callDuration) >= 60 ? formatCallTime(callDuration, false) : `${callDuration} sec`}
            </div>}
        </div>
    }

    const regularMsg = () => <div style={msgStyle(msg)}>
        {msg.text}
    </div>

    switch (msg.mode) {
        case 'call_started':
            return call('started')

        case 'call_ended':
            return call('ended')

        default:
            return regularMsg()
    }
}

const msgStyle = (msg: { isUser: boolean }) => ({
    background:  msg.isUser ? '#0094FF' : 'transparent',
    color: msg.isUser ? 'white' : 'black',
    marginRight: msg.isUser ? 0 : (isBrowser ? 80 : 32),
    marginLeft: msg.isUser ? (isBrowser ? 80 : 32) : 0,
    alignSelf: msg.isUser ? 'flex-end' : 'flex-start',
    padding: '8px 16px',
    borderRadius: 16,
    fontSize: 16
})

export default ChatHistoryComponent