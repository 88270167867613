import { Dispatch, useEffect, useMemo, useRef, useState } from 'react'
import './css/ExhumanComponent.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentAvatarConfig, selectAvatarAnswer, setAvatarAnswer, selectIsVoiceMode, setIsVoiceMode, selectIsPTTActive, setIsChatShownInVoiceMode, selectIsBotThinking, selectIsLipsyncOn, setIsToInterrupt, selectIsToInterrupt, setIsLipsyncOn, setIsBotTalking, selectIsBotTalking, defaultIsLipsyncOn, setIsBotIntroTalking, selectIsBotIntroTalking, selectIsOnlyFirstIntroMessage } from '../../app/redux/defaultSlice'
import { customAlert, formatCallTime } from '../../app/utils'
import { useCookies } from 'react-cookie'
import { firstInteractionPlayedKey } from '../../app/const-keys'
import { AvatarConfig } from '../../app/types'
import Hls from 'hls.js'
import { AutoTextSize } from 'auto-text-size'
import { StartCallButton } from '../Supporting/ButtonsComponents'
import Lottie from "lottie-react"
import avatarSpinning from './../../animations/avatar-spinning.json'
import avatarGlowing from './../../animations/avatar-glowing.json'
import { voiceModeMaxAllowedTimeSeconds } from '../../app/config'
import { selectAvatarInCallState } from '../../app/redux/avatarsSlice'
import { BrowserView, isBrowser, isMobile, MobileView } from 'react-device-detect'
import { isDLAI } from '../../app/settings'

const onlineWidth = 50
const onlineSideGap = 16
const titleGap = 8
const headSide = 300
const circleOffset = 42

export const defaultExhumanWidth = 300
export const exhumanInVoiceModeWidthSmall = 504

export default ({ startLivekitConnection, isConnectingLivekit, isConnectedLivekit, hide_ }: {
    startLivekitConnection: () => void,
    isConnectingLivekit: boolean,
    isConnectedLivekit: boolean,
    hide_?: boolean
}) => {

    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    const isVoiceMode = useSelector(selectIsVoiceMode)

    const [callSeconds, setCallSeconds] = useState(0)

    const isPTTActive = useSelector(selectIsPTTActive)
    const isBotThinking = useSelector(selectIsBotThinking)
    const isBotTalking = useSelector(selectIsBotTalking)

    useEffect(() => {
        let int: any
        if (isVoiceMode) {
            setCallSeconds(0)
            int = setInterval(() => {
                setCallSeconds(prev => prev + 1)
            }, 1000)
        }

        return () => {
            if (int) {
                clearInterval(int)
            }
        }
    }, [isVoiceMode])

    const avatarHeadRadius = isVoiceMode ? 150 : 16

    const [callStatus, setCallStatus] = useState('')
    useEffect(() => {
        if (isConnectedLivekit) {
            setCallStatus('')
            return
        }

        if (isPTTActive) {
            setCallStatus('Listening')

        } else if (isBotThinking) {
            setCallStatus('Thinking')

        } else if (isBotTalking) {
            setCallStatus('Talking')

        } else {
            setCallStatus('')
        }
    }, [isPTTActive, isBotThinking, isBotTalking, isConnectedLivekit])

    const isDLAIOn = isDLAI()

    const avatarInCallState = useSelector(selectAvatarInCallState)

    const animationData = useMemo(() => {
        switch (avatarInCallState) {
            case 'avatar_idle':
                return null
            case 'avatar_speaking':
                return avatarGlowing
            case 'avatar_listening':
                return avatarSpinning
        }
    }, [avatarInCallState])


    return <>
        <div className='h-full' style={{
            borderRadius: 20,
            width: '100%',
            display: hide_ ? 'none' : undefined,
            background: isMobile ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), linear-gradient(180deg, #FFF 0%, #B6DBFF 100%)' : undefined
        }}>
            <div className='exh__column flex flex-col' style={{
                width: '100%',
            }}>

                {isBrowser && <NameBlock />}

                {isVoiceMode && <div className='relative w-full'>
                    <div className='exh__bot-call-status absolute top-[10px] left-0 right-0 w-full text-center'>
                        {callStatus}
                    </div>
                </div>}

                <div className='exh__profession mb-[24px]'
                    style={{
                        opacity: isVoiceMode ? 0 : undefined,
                        alignSelf: isVoiceMode ? undefined : 'flex-start'
                    }}>
                    {avatarConfig?.profession}
                </div>

                <div className='exh__bot-head relative' style={{
                    width: headSide,
                    height: headSide,
                    marginTop: isVoiceMode ? (isBrowser ? 40 : 0) : undefined
                }}>
                    {!isVoiceMode && <img
                        style={{ borderRadius: 16 }}
                        width={headSide}
                        height={headSide}
                        src={avatarConfig?.image_url}
                    />}

                    <div style={{ opacity: isVoiceMode ? 1 : 0 }}>
                        <video
                            style={{ borderRadius: avatarHeadRadius, zIndex: 10 }}
                            id='video-idle'
                            className='exh__bot-head absolute top-0 left-0 bottom-0 right-0'
                            autoPlay
                            loop
                            src={avatarConfig?.exhuman_idle_url}
                            playsInline
                            webkit-playsinline
                            controls={false}
                        />
                        <video
                            style={{ borderRadius: avatarHeadRadius }}
                            id='video-streaming'
                            className='exh__bot-head absolute top-0 left-0 bottom-0 right-0 opacity-0'
                            autoPlay
                            playsInline
                            webkit-playsinline
                            controls={false}
                        />
                    </div>

                    {isVoiceMode && <div style={{
                        position: 'absolute',
                        left: -circleOffset / 2,
                        top: -circleOffset / 2,
                        width: headSide + circleOffset,
                        height: headSide + circleOffset
                    }}>
                        <Lottie animationData={animationData} loop={true} />
                    </div>}
                    <audio id='audio' />
                </div>

                <div className='exh__bot-head-below flex flex-col'>

                    {!isVoiceMode && <div className='exh__bio mb-[15px]'>
                        {avatarConfig?.bio}
                    </div>}

                    {isMobile && <NameBlock small />}

                    {isVoiceMode && <div style={{ color: '#667085', marginTop: (isBrowser ? 20 : -5) }} className='flex justify-center'>
                        {formatCallTime(callSeconds)}
                    </div>}

                    {isVoiceMode && <div className='max-w-[400px] m-[4px] opacity-40 text-center mt-[20px] mb-[12px]' style={{ fontSize: 14 }}>
                        This is an AI avatar of {avatarConfig?.display_name}. Check for accuracy.<br />
                        Voice calls are currently capped at 10 mins.<br />Background noise and multiple speakers may affect quality.
                    </div>}

                </div>

                {!isVoiceMode && <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 32,
                    flexDirection: 'column',
                    gap: 16,

                }}>
                    <StartCallButton title='Start a Call' onClick={startLivekitConnection} />

                    {isDLAIOn && <div className='flex flex-row justify-center'>
                        <a href='https://realavatar.ai' target='_blank'>
                            <img src='/images/powered-by-ra.svg' />
                        </a>
                    </div>}
                </div>}

                {isConnectingLivekit && <div className='ml-[10px]'>Connecting...</div>}

                <div className='flex-grow max-h-full'></div>

            </div>
        </div>

        <AnswerHandler />
        <IntroMessageChecker
            avatarConfig={avatarConfig}
            isConnectedLivekit={isConnectedLivekit}
        />
        <VoiceModeSwitchHandler avatarConfig={avatarConfig} />
    </>
}

const NameBlock = ({ small }: {
    small?: boolean
}) => {
    const isVoiceMode = useSelector(selectIsVoiceMode)
    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    const verifiedSide = avatarConfig?.is_verified ? (small ? 18 : 24) : 0
    const onlineBadgeWidth = 50
    const gaps = (avatarConfig?.is_verified ? titleGap : 0)

    let notVoiceModeAligning = ''
    if (!isVoiceMode) {
        notVoiceModeAligning = ` - ${(onlineWidth + onlineSideGap + titleGap)}px`
    }

    return <div className='w-full' style={{
        gap: titleGap,
        display: 'flex',
        alignItems: 'center',
        justifyContent: isVoiceMode ? 'center' : undefined,
        position: 'relative',
        marginTop: isVoiceMode ? 36 : undefined
    }}>
        <div className='exh__avatar-name mb-[8px]'
            style={{
                maxWidth: `calc(100% - ${verifiedSide + gaps}px${notVoiceModeAligning})`,
            }}
        >
            <AutoTextSize maxFontSizePx={small ? 18 : 30} className=''>
                {avatarConfig?.display_name}
            </AutoTextSize>
        </div>

        {avatarConfig?.is_verified && <div className='pb-[6px]'>
            <img width={verifiedSide} height={verifiedSide} src='/images/verified.svg' />
        </div>}

        {!isVoiceMode && <div className='_pb-[6px] _ml-[4px]' style={{
            width: onlineWidth,
            right: onlineSideGap,
            top: 5,
            position: 'absolute'
        }}>
            <img width={onlineBadgeWidth} src='/images/online.svg' />
        </div>}
    </div>
}

const VoiceModeSwitchHandler = ({ avatarConfig }: {
    avatarConfig: AvatarConfig | null
}) => {
    const dispatch = useDispatch()

    const isVoiceMode = useSelector(selectIsVoiceMode)
    const isLipsyncOn = useSelector(selectIsLipsyncOn)
    const firstRunSentinel = useRef(false)

    const audioElement = document.getElementById('audio') as HTMLAudioElement

    const resetVoiceModeFlags = () => {
        dispatch(setIsVoiceMode(false))
        dispatch(setIsLipsyncOn(defaultIsLipsyncOn))
        dispatch(setIsChatShownInVoiceMode(false))
        dispatch(setIsToInterrupt(false))
    }

    useEffect(() => {
        if (firstRunSentinel.current == false || !avatarConfig) {
            firstRunSentinel.current = true
            return
        }

        audioElement?.pause()

        if (!isVoiceMode) {
            resetVoiceModeFlags()
        }

        switchVideoToIdle()

    }, [isVoiceMode])

    useEffect(() => {
        if (!isLipsyncOn) {
            switchVideoToIdle(true)
        }
    }, [isLipsyncOn])

    useEffect(() => {
        return () => {
            resetVoiceModeFlags()
        }
    }, [])

    useEffect(() => {
        let timeoutId: any
        if (isVoiceMode) {
            timeoutId = setTimeout(() => {
                dispatch(setIsVoiceMode(false))
            }, voiceModeMaxAllowedTimeSeconds * 1000)
        }

        return () => clearTimeout(timeoutId)
    }, [isVoiceMode])

    return <></>
}

const AnswerHandler = () => {

    const dispatch = useDispatch()
    const avatarAnswer = useSelector(selectAvatarAnswer)
    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    const isToInterrupt = useSelector(selectIsToInterrupt)
    useEffect(() => {
        const videoStreamingElement = document.getElementById('video-streaming') as HTMLVideoElement
        const audioElement = document.getElementById('audio') as HTMLAudioElement

        if (isToInterrupt) {
            try {
                audioElement?.pause()
                videoStreamingElement?.pause()
            } catch (e) {
                console.error(e)
            }
        }
    }, [isToInterrupt])

    const answerSentinelRef = useRef(0)

    const isPTTActive = useSelector(selectIsPTTActive)
    useEffect(() => {
        if (isPTTActive) {
            answerSentinelRef.current += 1

            const audioElement = document.getElementById('audio') as HTMLAudioElement
            audioElement.pause()

            switchVideoToIdle()
        }
    }, [isPTTActive])

    const hls: Hls | null = useMemo(() => {
        if (Hls.isSupported()) {
            const config = {
                playlistLoadPolicy: {
                    default: {
                        maxTimeToFirstByteMs: 10000,
                        maxLoadTimeMs: 20000,
                        timeoutRetry: {
                            maxNumRetry: 20,  // When GAE is slow it might take more time.
                            retryDelayMs: 0,
                            maxRetryDelayMs: 0,
                        },
                        errorRetry: {
                            maxNumRetry: 100,  // If the playlist doesn't have sgements yet, keep trying.
                            retryDelayMs: 100,
                            maxRetryDelayMs: 500,
                        },
                    },
                },
            };
            return new Hls(config)
        }
        return null
    }, [])

    useEffect(() => {
        if (!avatarAnswer) {
            return
        }
        if (!avatarConfig) {
            customAlert('no avatar config, please check url for a real avatar')
            return
        }


        (async () => {
            answerSentinelRef.current += 1
            const currentAnswerSentinel = answerSentinelRef.current

            dispatch(setIsToInterrupt(true))

            const m3u8Url = avatarAnswer.m3u8Url
            if (m3u8Url) {
                const ok = await waitForFileAvailability(m3u8Url)

                if (currentAnswerSentinel != answerSentinelRef.current) {
                    return
                }

                if (ok) {
                    runStream(hls, m3u8Url, dispatch)

                } else {
                    customAlert(`Stream isn't available before timeout`)
                    return
                }

            } else {
                const audioBlob = avatarAnswer.audioBlob
                const audioElement = document.getElementById('audio') as HTMLAudioElement
                if (audioBlob) {
                    const url = URL.createObjectURL(audioBlob)
                    audioElement.src = url
                    dispatch(setIsBotTalking(true))
                    audioElement.play()
                    audioElement.addEventListener('ended', () => {
                        dispatch(setIsToInterrupt(false))
                        dispatch(setIsBotTalking(false))
                    })

                    switchVideoToIdle()
                }
            }
        })()

        dispatch(setAvatarAnswer(null))

    }, [avatarAnswer])

    return <></>
}

const waitForFileAvailability = async (url: string, timeoutSec: number = 20) => {
    let attempt = 0
    const recheckInterval = 100
    const maxAttempts = timeoutSec * 1000 / recheckInterval

    return new Promise((resolve, reject) => {
        const checkFile = async () => {
            try {
                const response = await fetch(url, { method: 'HEAD' })

                if (response.ok) {
                    console.log('File is available for download.')
                    return resolve(true)
                } else {
                    console.log(`File is not available. Status: ${response.status}`)
                }
            } catch (error) {
                console.error('Error checking file availability:', error)
            }

            attempt++
            if (attempt < maxAttempts) {
                setTimeout(checkFile, recheckInterval)
            } else {
                console.log('Max attempts reached. File not available.')
                return resolve(false)
            }
        };

        checkFile()
    });
}

const switchVideoToIdle = (notPausingSteaming: boolean = false) => {
    const videoIdleElement = document.getElementById('video-idle') as HTMLVideoElement
    const streamingVideoElement = document.getElementById('video-streaming') as HTMLVideoElement
    if (!videoIdleElement || !streamingVideoElement) {
        return
    }

    streamingVideoElement.style.opacity = '0.0'
    videoIdleElement.style.opacity = '1.0'

    videoIdleElement.play().catch(() => { })

    if (!notPausingSteaming) {
        streamingVideoElement.pause()
    }
}

const switchVideoToStreaming = async (notPlayingStreaming: boolean = false) => {
    const audioElement = document.getElementById('audio') as HTMLAudioElement
    const videoIdleElement = document.getElementById('video-idle') as HTMLVideoElement
    const streamingVideoElement = document.getElementById('video-streaming') as HTMLVideoElement

    videoIdleElement.style.opacity = '0.0'
    streamingVideoElement.style.opacity = '1.0'

    if (!notPlayingStreaming) {
        await streamingVideoElement.play()
    }

    try {
        await videoIdleElement.pause()
        await audioElement.pause()
    } catch (e) {
        console.error(e)
    }
}

const runStream = (hls: Hls | null, url: string, dispatch: Dispatch<any>) => {
    const audioElement = document.getElementById('audio') as HTMLAudioElement
    const videoElement = document.getElementById('video-idle') as HTMLVideoElement
    const streamingVideoElement = document.getElementById('video-streaming') as HTMLVideoElement
    const videoSrc = url


    if (hls) {
        hls.loadSource(videoSrc)
        hls.attachMedia(streamingVideoElement)

        let fragAlreadyLoaded = false
        hls.on(Hls.Events.FRAG_LOADED, () => {
            if (!fragAlreadyLoaded) {
                switchVideoToStreaming()
                dispatch(setIsBotTalking(true))
                fragAlreadyLoaded = true
            }
        })

        hls.on(Hls.Events.ERROR, function (event, data) {
            console.error('HLS error:', data)
            dispatch(setIsBotTalking(false))
        })

        streamingVideoElement.addEventListener('ended', () => {
            switchVideoToIdle()
            dispatch(setIsToInterrupt(false))
            dispatch(setIsBotTalking(false))
        })

    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        streamingVideoElement.src = videoSrc;
        streamingVideoElement.addEventListener('canplay', () => {
            streamingVideoElement.play()
            audioElement.pause()
            switchVideoToStreaming()
            dispatch(setIsBotTalking(true))
        })

        streamingVideoElement.addEventListener('ended', () => {
            switchVideoToIdle()
            dispatch(setIsToInterrupt(false))
            dispatch(setIsBotTalking(false))
        })

    } else {
        console.error('HLS is not supported in this browser.')
        dispatch(setIsBotTalking(false))
    }
}

const IntroMessageChecker = ({ avatarConfig, isConnectedLivekit }: {
    avatarConfig: AvatarConfig | null,
    isConnectedLivekit: boolean
}) => {
    const dispatch = useDispatch()
    const [cookies, setCookie] = useCookies([firstInteractionPlayedKey])
    const isFirstInteractionPlayed = cookies[firstInteractionPlayedKey] ?? {}
    const isVoiceMode = useSelector(selectIsVoiceMode)
    const isOnlyFirstIntroMessage = useSelector(selectIsOnlyFirstIntroMessage)

    useEffect(() => {
        if (isConnectedLivekit) {
            return
        }

        setTimeout(async () => {
            const videoStreamingElement = document.getElementById('video-streaming') as HTMLVideoElement

            if (isVoiceMode &&
                isOnlyFirstIntroMessage &&
                !isFirstInteractionPlayed[avatarConfig?.name ?? ''] &&
                videoStreamingElement &&
                avatarConfig?.first_chat_message_video_url) {

                dispatch(setIsBotTalking(true))
                dispatch(setIsBotIntroTalking(true))

                videoStreamingElement.src = avatarConfig?.first_chat_message_video_url
                videoStreamingElement.loop = false
                videoStreamingElement.load()
                videoStreamingElement.play()

                const isFirstInteractionPlayedUpd = { ...isFirstInteractionPlayed }
                isFirstInteractionPlayedUpd[avatarConfig.name] = true
                setCookie(firstInteractionPlayedKey, isFirstInteractionPlayedUpd)

                videoStreamingElement.addEventListener('ended', () => {
                    dispatch(setIsBotTalking(false))
                    dispatch(setIsBotIntroTalking(false))

                    videoStreamingElement.loop = true
                    if (videoStreamingElement.src != avatarConfig.exhuman_idle_url) {
                        videoStreamingElement.src = avatarConfig.exhuman_idle_url
                        videoStreamingElement.load()
                    }
                })
            }
        }, 100)

    }, [avatarConfig?.id, isFirstInteractionPlayed[avatarConfig?.name ?? ''], isVoiceMode, isConnectedLivekit, isOnlyFirstIntroMessage])

    return <></>
}

