import { useEffect, useMemo, useState } from 'react'
import './css/SuggestedTopicsComponent.css'
import { useDispatch, useSelector } from 'react-redux';
import { selectAskedQuestions, setAskedQuestions, selectCurrentSuggestedTopics } from '../../app/redux/defaultSlice';
import { SuggestedTopics, SuggestedTopicsCategory } from '../../app/types';

export default ({ onCloseSuggestedTopics, onSelectQuestion }: {
    onCloseSuggestedTopics: () => void,
    onSelectQuestion: (q: string) => void
}) => {
    const dispatch = useDispatch()
    const suggestedTopics: SuggestedTopics | null = useSelector(selectCurrentSuggestedTopics)

    const askedQuestions = useSelector(selectAskedQuestions)
    const onSelectQuestionWrapper = (question: string) => {
        onSelectQuestion(question)
        dispatch(setAskedQuestions([...askedQuestions, question]))
    }

    const [selectedTabId, setSelectedTabId] = useState<string | null>(null)

    const tabs = useMemo(() => {
        return suggestedTopics?.map((category: SuggestedTopicsCategory, idx: number) => ({
            id: `${idx}`,
            name: category.category,
            questions: category.questions
        })) ?? []
    }, [suggestedTopics])

    useEffect(() => {
        if (tabs && tabs.length > 0) {
            setSelectedTabId(`${tabs[0].id}`)
        }
    }, [tabs])

    const currentQuestions: string[] = useMemo(() => {
        for (var i = 0; i < tabs.length; i++) {
            const tab = tabs[i]
            if (tab.id == selectedTabId) {
                return tab.questions
            }
        }
        return []

    }, [selectedTabId, tabs])
    const questionsWithoutAsked: string[] = currentQuestions.filter((q: string) => !askedQuestions.includes(q))

    const topOffsetHardcoded = 120 // SectionsTabs and suggested-topics__header

    return <div className='suggested-topics__body'>
        <div className='suggested-topics__header'>
            <div>
                {cloudSvg}
            </div>
            <div className='suggested-topics__title flex-grow'>
                Suggested topics
            </div>
            <div className='cursor-pointer' onClick={onCloseSuggestedTopics}>
                {xSvg}
            </div>
        </div>

        <SectionsTabs
            tabs={tabs}
            selectedTabId={selectedTabId}
            setSelectedTabId={setSelectedTabId}
        />

        <div className='px-[24px] py-[10px]' style={{ height: `calc(100% - ${topOffsetHardcoded}px)` }}>
            <QuestionsPlainListComponent
                questions={questionsWithoutAsked}
                onSelectQuestion={onSelectQuestionWrapper} />
        </div>
    </div>
}

const QuestionsPlainListComponent = ({ questions, onSelectQuestion: onSelect }: {
    questions: string[],
    onSelectQuestion: (q: string) => void
}) => {

    return <div className='mb-[28px] h-full'>
        <div className='suggested-topic__questions overflow-y-scroll pb-[27px] no-scrollbar h-full' /*style={{ height: 420 }}*/>
            {questions.map((question: string, i) => <div
                key={i}
                className='suggested-topic__question'
                onClick={() => {
                    onSelect(question)
                }}
            >
                <span>{question}</span>
                <span className='self-start ml-[8px] suggested-topic__question-arrow'>{sendArrowSvg}</span>
            </div>)}

            { questions.length == 0 && <div className='text-gray-500'>No questions</div> }
        </div>
    </div>
}

const SectionsTabs = ({ tabs, selectedTabId, setSelectedTabId }: {
    tabs: { id: string, name: string }[],
    selectedTabId: string | null,
    setSelectedTabId: (newId: string) => void
}) => {
    return <div className='suggested-topic__header-sections'>
        {tabs.map((tab, i) =>
            <div
                key={i}
                className={`suggested-topic__header-section ${tab.id == selectedTabId ? 'selected' : ''}`}
                onClick={() => setSelectedTabId(tab.id)}
            >
                {tab.name}
            </div>)}
    </div>
}

const sendArrowSvg = <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#D8EFFF" />
    <path d="M22 29V15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 22L22 15L29 22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const cloudSvg = <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.124 23.8542C16.5701 24.589 14.833 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13C25 14.8333 24.5889 16.5705 23.8539 18.1246L24.9998 25.0001L18.124 23.8542Z" fill="#0094FF" />
    <path d="M18.124 23.8542L18.2884 22.8678L17.9795 22.8163L17.6965 22.9501L18.124 23.8542ZM23.8539 18.1246L22.9499 17.697L22.816 17.9801L22.8675 18.289L23.8539 18.1246ZM24.9998 25.0001L24.8354 25.9865L26.2164 26.2167L25.9862 24.8357L24.9998 25.0001ZM13 26C14.9839 26 16.8667 25.5549 18.5515 24.7582L17.6965 22.9501C16.2734 23.6231 14.6821 24 13 24V26ZM0 13C0 20.1797 5.8203 26 13 26V24C6.92487 24 2 19.0751 2 13H0ZM13 0C5.8203 0 0 5.8203 0 13H2C2 6.92487 6.92487 2 13 2V0ZM26 13C26 5.8203 20.1797 0 13 0V2C19.0751 2 24 6.92487 24 13H26ZM24.7579 18.5521C25.5548 16.8672 26 14.9842 26 13H24C24 14.6823 23.623 16.2738 22.9499 17.697L24.7579 18.5521ZM25.9862 24.8357L24.8403 17.9602L22.8675 18.289L24.0134 25.1645L25.9862 24.8357ZM17.9596 24.8406L24.8354 25.9865L25.1642 24.0137L18.2884 22.8678L17.9596 24.8406Z" fill="#0094FF" />
</svg>

const xSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
        <path d="M18 6L6 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
</svg>
