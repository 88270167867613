import { useDispatch, useSelector } from "react-redux"
import { selectCurrentAvatarConfig, selectIsBotIntroTalking, selectIsBotThinking, selectIsChatShownInVoiceMode, selectIsLipsyncOn, selectIsPTTActive, selectIsToInterrupt, setIsChatShownInVoiceMode, setIsLipsyncOn, setIsPTTActive, setIsToInterrupt, setIsVoiceMode } from "../../app/redux/defaultSlice"
import PTTComponent from "./PTTHandler"
import { useEffect } from "react"
import './css/CallButtonsMobileComponent.css'

const CallButtonsMobileStack = ({ isLivekitConnection, isMicEnabled, setIsMicEnabled, hangup }: {
    isLivekitConnection: boolean,
    isMicEnabled: boolean,
    setIsMicEnabled: (isMicEnabled: boolean) => void,
    hangup: () => void,
}) => {

    const dispatch = useDispatch()
    const avatarConfig = useSelector(selectCurrentAvatarConfig)
    const isPTTActive = useSelector(selectIsPTTActive)
    const isChatShownInVoiceMode = useSelector(selectIsChatShownInVoiceMode)
    const isLipsyncOn = useSelector(selectIsLipsyncOn)
    const isBotIntroTalking = useSelector(selectIsBotIntroTalking)

    const isThinking = useSelector(selectIsBotThinking)
    useEffect(() => {
        if (isThinking) {
            dispatch(setIsToInterrupt(true))
        }
    }, [isThinking])


    return (
        <div className='w-full mb-[35px]'>
            <div className='w-full flex justify-between items-center px-[32px]'>
                <div onClick={() => setIsMicEnabled(!isMicEnabled)}>

                    <div className='relative call-buttons-mobile-container__button-title'>
                        <div className='absolute bottom-[4px] left-0 right-0 text-center'>
                            { isMicEnabled ? 'Mute' : 'Unmute'}
                        </div>
                    </div>

                    <img width={64} height={64} src={`/images/voice-mode-mob-reg-mic-${isMicEnabled ? 'on' : 'off'}.svg`} />

                </div>

                <div>
                    <img src='/images/voice-mode-dots.svg' />
                </div>

                <div onClick={() => {
                    dispatch(setIsVoiceMode(false))
                    hangup()
                }}>

                    <div className='relative call-buttons-mobile-container__button-title'>
                        <div className='absolute bottom-[4px] left-0 right-0 text-center'>
                            End call
                        </div>
                    </div>

                    <img width={64} height={64} src='/images/voice-mode-mob-hang-up.svg' />
                </div>
            </div>
        </div>
    )
}

export default CallButtonsMobileStack