import { useEffect, useRef, useState } from "react";
import { closeStream, connect, loadWebrtc } from '../app/webrtc/webRtcPlayer-bridge'
import ChatComponent from "./Chat/ChatComponent";
import { useCookies } from "react-cookie";
import { authDataKey } from "../app/const-keys";
import * as amplitude from '@amplitude/analytics-browser';
import FirstScreensComponent from "./FirstScreens/FirstScreensComponent";
import ChatWithExhumanComponent from "./Chat/ChatGeneralComponent";
import { Route, Routes, useNavigate } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { sendSessionIntro } from "../app/api";
import DLAIAuth from "./Infra/DLAIAuth";

const AppComponent = () => {
    const navigate = useNavigate()
    const [isOverlay, setIsOverlay] = useState(true);

    (window as any).showPlayOverlay = () => {
        setIsOverlay(true)
    }

    (window as any).showConnectOverlay = () => {
        setIsOverlay(true)
    }

    useEffect(() => {
        loadWebrtc()

        window.addEventListener('resize', function () {
            const playerElement = this.document.getElementById('player')
            if (playerElement) {
                if (window.innerHeight * 1.77 < window.innerWidth) {
                    playerElement.style.height = 'calc(100vw / 1.77)'
                    playerElement.style.width = 'calc(100vw + 0px)'
                } else {
                    playerElement.style.height = '100vh'
                    playerElement.style.width = 'calc(100vh * 1.77 + 0px)'
                }
            }
        });
    }, [])

    const logout = () => {
        setIsOverlay(true)
        closeStream()
    }
    const [cookies] = useCookies<string>([authDataKey]);
    const authData = cookies[authDataKey]
    const wasAuthRef = useRef(false)
    useEffect(() => {
        if (authData) {
            wasAuthRef.current = true

            if (authData.email) {
                amplitude.setUserId(authData.email)
            }

        } else if (wasAuthRef.current == true) {
            logout()
            navigate('/')
        }
    }, [authData])

    return (
        <>
            <div style={{ position: 'relative', height: '100svh' }}>
                <Routes>
                    <Route path='/metahuman/*' element={
                        <>
                            {isOverlay &&
                                <FirstScreensComponent
                                    onStartSession={async () => {
                                        if (isBrowser) {
                                            connect()
                                        }
                                        setIsOverlay(false)

                                        setTimeout(() => {
                                            sendSessionIntro()
                                        }, 3000)
                                    }} />
                            }

                            <div id='player'></div>

                            {!isOverlay && <div
                                style={{
                                    width: '50%',
                                    marginLeft: '50%',
                                    height: '100%',
                                }}>
                                <ChatComponent metahuman />
                            </div>}
                        </>
                    } />

                    <Route path='/chat' element={
                        <ChatWithExhumanComponent />
                    } />
                    <Route path='/*' element={
                        <FirstScreensComponent onStartSession={() => {
                            navigate('chat')
                        }} />
                    } />

                </Routes>
            </div>

            <DLAIAuth />

        </>
    )
}

export default AppComponent;