import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { AvatarAnswer, AvatarConfig, AvatarInCallState } from '../types';

export interface AvatarsState {
    avatarConfigs: AvatarConfig[] | null;
    avatarsLoadingError: string | null;
    avatarInCallState: AvatarInCallState;
}

const initialState: AvatarsState = {
    avatarConfigs: null,
    avatarsLoadingError: null,
    avatarInCallState: 'avatar_idle'
};

export const avatarsSlice = createSlice({
    name: 'avatars',
    initialState,
    reducers: {
        setAvatarConfigs: (state, action: PayloadAction<AvatarConfig[]>) => {
            state.avatarConfigs = action.payload;
        },
        setAvatarsLoadingError: (state, action: PayloadAction<string | null>) => {
            state.avatarsLoadingError = action.payload;
        },
        setAvatarInCallState: (state, action: PayloadAction<AvatarInCallState>) => {
            state.avatarInCallState = action.payload;
        },
    },
});

export const {
    setAvatarConfigs,
    setAvatarInCallState
} = avatarsSlice.actions;

export const selectAvatarConfigs = (state: RootState) => state.avatars.avatarConfigs;
export const selectAvatarInCallState = (state: RootState) => state.avatars.avatarInCallState;
export default avatarsSlice.reducer;
