import ChatComponent, { chatFullWidth, chatSideGap, chatWidth } from "./ChatComponent"
import ExhumanComponent, { defaultExhumanWidth, exhumanInVoiceModeWidthSmall } from "./ExhumanComponent"
import './css/ChatWithExhumanComponent.css'
import ProfileWidget from "./ProfileWidget"
import HeaderComponent, { headerHeight } from "./HeaderComponent"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentAvatarConfig, selectIsChatShownInVoiceMode, selectIsVoiceMode, setIsVoiceMode } from "../../app/redux/defaultSlice"
import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import CallButtonsDesktopStack from "./CallButtonsDesktopComponent"
import { StartCallButton } from "../Supporting/ButtonsComponents"
import { LiveKitRoom, RoomAudioRenderer, useLocalParticipant, useRoomContext, useRoomInfo } from "@livekit/components-react"
import { DisconnectReason, MediaDeviceFailure } from "livekit-client"
import { connectLivekit, endCall, startCall } from "../../app/api"
import { customAlert } from "../../app/utils"
import { setAvatarInCallState } from "../../app/redux/avatarsSlice"
import { AvatarInCallState } from "../../app/types"
import { MobileView, BrowserView, isMobile, isBrowser } from 'react-device-detect';
import MobileAvatarHeaderComponent, { mobileAvatarHeaderHeight } from "./MobileAvatarHeaderComponent"
import DLAIDisclaimerComponent from "../Supporting/DLAIDisclaimerComponent"
import CallButtonsMobileStack from "./CallButtonsMobileComponent"
import { isDLAI } from "../../app/settings"

const gap = 25

const ChatGeneralComponent = () => {
    const dispatch = useDispatch()
    const currentAvatar = useSelector(selectCurrentAvatarConfig)
    const navigate = useNavigate()

    useEffect(() => {
        if (!currentAvatar) {
            navigate('/')
        }
    }, [currentAvatar])

    const isVoiceMode = useSelector(selectIsVoiceMode)
    const isChatShownInVoiceMode = useSelector(selectIsChatShownInVoiceMode)

    let exhWidth
    let isMinimalMargin = false
    let contentWidth = undefined
    if (isVoiceMode) {
        if (isChatShownInVoiceMode) {
            exhWidth = exhumanInVoiceModeWidthSmall
            contentWidth = exhumanInVoiceModeWidthSmall + gap + chatFullWidth

        } else {
            exhWidth = '100%'
            isMinimalMargin = true
        }

    } else {
        contentWidth = defaultExhumanWidth + gap + chatFullWidth
        exhWidth = defaultExhumanWidth
    }

    const [isConnecting, setIsConnecting] = useState(false)
    const [isMicEnabled, setIsMicEnabled] = useState(true)
    const [livekitConnectionDetails, updateLivekitConnectionDetails] = useState<LivekitConnectionDetails | undefined>(undefined)
    const onConnectLivekitButtonClicked = useCallback(async () => {
        if (!currentAvatar) {
            return
        }

        setIsConnecting(true)

        try {
            const response = await startCall(currentAvatar.name)
            const connectionDetailsData = await response.json()
            updateLivekitConnectionDetails(connectionDetailsData)

        } catch (err) {
            alert(`${err}`)

        } finally {
            setIsConnecting(false)
        }

    }, [currentAvatar])

    useEffect(() => {
        if (livekitConnectionDetails) {
            dispatch(setIsVoiceMode(true))
        } else {
            dispatch(setIsVoiceMode(false))
        }
    }, [livekitConnectionDetails])

    useEffect(() => {
        return () => {
            if (isVoiceMode) {
                if (!livekitConnectionDetails || !currentAvatar) {
                    return
                }
                endCall(currentAvatar.name, livekitConnectionDetails.room_name)
                    .catch(err => {
                        console.error(`Error ending call: ${err}`)
                    })
                    .finally(() => {
                        updateLivekitConnectionDetails(undefined)
                    })
            }
        }
    }, [isVoiceMode])

    const isDLAIOn = isDLAI()

    return <>
        <LiveKitRoom
            token={livekitConnectionDetails?.participant_token}
            serverUrl={livekitConnectionDetails?.server_url}
            connect={livekitConnectionDetails !== undefined}
            audio={true}
            video={false}
            onMediaDeviceFailure={onDeviceLivekitFailure}
            onDisconnected={(reason: DisconnectReason | undefined) => {
                console.log('livekit onDisconnected reason', reason)
                updateLivekitConnectionDetails(undefined);
            }}
            className="grid grid-rows-[2fr_1fr] items-center"
        >
            <RoomAudioRenderer />
            <MicMuteHandler isMicEnabled={isMicEnabled} />
            <ActiveSpeakersHandler />
        </LiveKitRoom>

        <DLAIDisclaimerComponent />

        {isBrowser ?

            <div className='h-full' style={{ background: isVoiceMode ? 'linear-gradient(180deg, #fff 0%, #0094FF40 100%)' : 'linear-gradient(180deg, #F9FAFB 0%, #E3E7EC 100%)' }}>

                {!isDLAIOn && <HeaderComponent />}

                <div
                    className='main-block'
                    style={{
                        display: 'flex',
                        width: contentWidth,
                        minWidth: 500,
                        marginLeft: isMinimalMargin ? 0 : `max(50% - ${contentWidth! / 2}px, 25px)`,
                        height: `calc(100% - ${isDLAIOn ? 0 : headerHeight}px)`,
                    }}>

                    <div
                        style={{
                            height: '100%',
                            paddingTop: 25,
                            marginRight: isVoiceMode ? 0 : gap,
                            width: exhWidth,
                            position: 'relative'
                        }}>
                        <ExhumanComponent
                            startLivekitConnection={onConnectLivekitButtonClicked}
                            isConnectingLivekit={isConnecting}
                            isConnectedLivekit={livekitConnectionDetails != undefined}
                        />
                    </div>

                    <div
                        style={{
                            width: chatFullWidth,
                            height: '100%',
                            marginBottom: 25,
                            display: (isVoiceMode && !isChatShownInVoiceMode) ? 'none' : undefined
                        }}>
                        <ChatComponent />
                    </div>

                    <div className='flex justify-center absolute bottom-0 left-0 right-0'>
                        {isVoiceMode &&
                            <CallButtonsDesktopStack
                                isLivekitConnection={livekitConnectionDetails != undefined}
                                isMicEnabled={isMicEnabled}
                                setIsMicEnabled={setIsMicEnabled}
                                hangup={() => dispatch(setIsVoiceMode(false))} />}
                    </div>
                </div>
            </div>

            :

            // mobile
            <div
                style={{ height: isVoiceMode ? '100%' : `calc(100% - ${mobileAvatarHeaderHeight}px - ${isDLAIOn ? 0 : headerHeight}px)` }}>

                {!isDLAIOn && <HeaderComponent />}

                <ExhumanComponent
                    startLivekitConnection={onConnectLivekitButtonClicked}
                    isConnectingLivekit={isConnecting}
                    isConnectedLivekit={livekitConnectionDetails != undefined}
                    hide_={!isVoiceMode}
                />

                {!isVoiceMode &&
                    <>
                        <MobileAvatarHeaderComponent
                            onStartCall={onConnectLivekitButtonClicked}
                            isConnectingLivekit={isConnecting}
                        />
                        <ChatComponent />
                    </>
                }

                <div className='flex justify-center absolute bottom-0 left-0 right-0'>
                    {isVoiceMode &&
                        <CallButtonsMobileStack
                            isLivekitConnection={livekitConnectionDetails != undefined}
                            isMicEnabled={isMicEnabled}
                            setIsMicEnabled={setIsMicEnabled}
                            hangup={() => dispatch(setIsVoiceMode(false))} />}
                </div>

            </div>
        }
    </>
}

const ActiveSpeakersHandler = () => {
    const dispatch = useDispatch()

    const [avatarInCallStateInstant, setAvatarInCallStateInstant] = useState<AvatarInCallState>('avatar_idle')
    const avatarInCallStatePreviousRef = useRef<AvatarInCallState>('avatar_idle')
    useEffect(() => {
        const throttleTime = (avatarInCallStatePreviousRef.current == 'avatar_idle' && avatarInCallStateInstant != 'avatar_idle') ? 10 : 1000

        const timeout = setTimeout(() => {
            dispatch(setAvatarInCallState(avatarInCallStateInstant))
        }, throttleTime)

        avatarInCallStatePreviousRef.current = avatarInCallStateInstant

        return () => clearTimeout(timeout)
    }, [avatarInCallStateInstant])

    const isVoiceMode = useSelector(selectIsVoiceMode)
    useEffect(() => {
        return () => { dispatch(setAvatarInCallState('avatar_idle')) }
    }, [isVoiceMode])

    const room = useRoomContext()
    useEffect(() => {
        if (room) {
            const handleActiveSpeakersChanged = (activeSpeakers: any[]) => {
                if (activeSpeakers.length != 1) {
                    setAvatarInCallStateInstant('avatar_idle')

                } else {
                    setAvatarInCallStateInstant(activeSpeakers[0].isLocal ? 'avatar_listening' : 'avatar_speaking')
                }
            }

            room.on('activeSpeakersChanged', handleActiveSpeakersChanged)
            return () => {
                room.off('activeSpeakersChanged', handleActiveSpeakersChanged)
            }
        }
    }, [room])

    return <></>
}

const MicMuteHandler = ({ isMicEnabled }: { isMicEnabled: boolean }) => {
    const { localParticipant } = useLocalParticipant()
    useEffect(() => {
        if (localParticipant) {
            localParticipant.trackPublications.forEach(trackPublication => {
                if (isMicEnabled) {
                    trackPublication.audioTrack?.unmute()
                } else {
                    trackPublication.audioTrack?.mute()
                }
            })
        }
    }, [localParticipant, isMicEnabled])

    return null
}

const onDeviceLivekitFailure = (error?: MediaDeviceFailure) => {
    console.error(error)
    alert("Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab")
}

type LivekitConnectionDetails = {
    server_url: string;
    room_name: string;
    participant_name: string;
    participant_token: string;
}

export default ChatGeneralComponent