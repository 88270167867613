import { isDLAI, isDLAIDisclaimerAccepted, setIsDLAIDisclaimerAccepted } from "../../app/settings"
import './css/DLAIDisclaimerComponent.css'

export default function DLAIDisclaimerComponent() {
    const isAccepted = isDLAIDisclaimerAccepted()
    const isDLAIOn = isDLAI()

    return (isDLAIOn && !isAccepted) ? <>

        <div className='absolute bottom-0 left-0 right-0 top-0 bg-black opacity-50 z-10'></div>

        <div className='absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center'>
            <div className=' bg-white z-10 p-[24px] rounded-[16px] m-[8px] max-w-[500px]'>

                <div className='dlai-disclaimer-component-title mb-[24px]'>
                    Meet AI Andrew Ng!
                </div>

                <div className='dlai-disclaimer-component mb-[8px]'>
                    Talk with the AI avatar of Andrew Ng, founder of <a href="https://deeplearning.ai" target="_blank" className="dlai-disclaimer-component-link">DeepLearning.AI</a> and AI educator. Get to know Andrew, talk about you career and project ideas, have a fun chat. Please note that this is an AI representation and may provide inaccurate information - we recommend fact-checking any specific details shared.
                </div>

                <div className='dlai-disclaimer-component mb-[15px]'>
                    By clicking continue you agree to our <a href='https://www.realavatar.ai/terms-of-service' target='_blank' className='dlai-disclaimer-component-link'>Terms of Services</a> and that you have read our <a href='https://www.realavatar.ai/privacy-policy' target='_blank' className='dlai-disclaimer-component-link'>Privacy Policy</a>
                </div>

                <div className='flex flex-row justify-center mb-[15px]'>
                    <div className='flex flex-row justify-between gap-[8px] grow'>
                        <div className='dlai-disclaimer-component-button dlai-cancel max-w-[30%]' onClick={() => {
                            window.location.href = 'https://deeplearning.ai'
                        }}>
                            Cancel
                        </div>
                        <div className='dlai-disclaimer-component-button dlai-redish' onClick={() => {
                            setIsDLAIDisclaimerAccepted()
                        }}>
                            Accept & Continue
                        </div>
                    </div>
                </div>

                <div className='flex flex-row justify-center'>
                    <a href='https://realavatar.ai' target='_blank'>
                        <img src='/images/powered-by-ra.svg' />
                    </a>
                </div>

            </div>
        </div>

    </> : null
}