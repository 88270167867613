import { useState } from "react"
import ProfileWidget from "./ProfileWidget"
import { useNavigate } from "react-router-dom"
import PopupDiscoverComponent from "../FirstScreens/PopupDiscoverComponent"

export const headerHeight = 64

const HeaderComponent = ({ intro }: {
    intro?: boolean
}) => {

    const [showDiscover, setShowDiscover] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <div className='header' style={{ height: headerHeight }}>

                <img
                    onClick={() => navigate('/')}
                    src='/images/logo-long.png'
                    className='h-[32px] cursor-pointer'
                />

                <div className='flex-grow'></div>

                {!intro && <div className='header__button' onClick={() => setShowDiscover(true)}>Discover avatars </div>}

                <ProfileWidget />

            </div>

            {showDiscover && <PopupDiscoverComponent close={() => setShowDiscover(false)} />}
        </>
    )
}

export default HeaderComponent