import { useSelector } from "react-redux"
import { selectCurrentAvatarConfig } from "../../app/redux/defaultSlice"

export const mobileAvatarHeaderHeight = 56

export default function MobileAvatarHeaderComponent({ onStartCall, isConnectingLivekit }: {
    onStartCall: () => void,
    isConnectingLivekit: boolean
}) {
    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    return <div className="w-full px-4 bg-white border-b border-neutral-200 justify-between items-center inline-flex" style={{ height: mobileAvatarHeaderHeight }}>
        <div className="justify-start items-start gap-2 flex">
            <img className="w-10 h-[38.75px] rounded-[19.38px]" src={avatarConfig?.image_url} />
            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="justify-start items-center gap-2 inline-flex">
                    <div className="justify-center items-center gap-2 flex">
                        <div className="text-black text-base font-semibold font-['Inter'] leading-normal">{avatarConfig?.display_name}</div>
                        <div className="px-1 bg-[#3bb376]/20 rounded justify-center items-center gap-2.5 flex">
                            <div className="text-[#007b3c] text-xs font-medium font-['Inter'] leading-[18px]">online</div>
                        </div>
                    </div>
                </div>
                <div className="text-[#7f848f] text-sm font-normal font-['Inter'] leading-tight">{avatarConfig?.profession}</div>
            </div>
        </div>
        <img onClick={onStartCall} src='/images/mobile-start-call.svg' className='h-[36px] w-[130px]' style={{ opacity: isConnectingLivekit ? 0.7 : 1 }} />
    </div>
}