import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { fetchAvatarConfigs, loginDLAI } from "../../app/api"
import { customAlert, setAuth } from "../../app/utils"
import { AvatarConfig } from "../../app/types"
import { setCurrentAvatarConfigAndGetSuggestedTopics } from "../../app/redux/actions"
import { useDispatch } from "react-redux"
import * as amplitude from '@amplitude/analytics-browser';
import { setAvatarConfigs } from "../../app/redux/avatarsSlice"
import { setIsDLAI } from "../../app/settings"

const DLAIAuth = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const dlaiUserHash = searchParams.get('userHash')
    const [canFetchAvatar, setCanFetchAvatar] = useState(false)

    useEffect(() => {
        if (dlaiUserHash) {
            setIsDLAI()
            loginDLAI(dlaiUserHash)
                .then((authData) => {
                    setAuth(authData)
                    setCanFetchAvatar(true)
    
                })
                .catch((err: any) => {
                    customAlert(`login failed: ${err}`)
                    window.location.href = 'https://deeplearning.ai'
                })
        }
    }, [dlaiUserHash])

    useEffect(() => {
        if (canFetchAvatar) {
            fetchAvatarConfigs('andrew')
                .then((avatarConfigs: AvatarConfig[]) => {
                    if (avatarConfigs?.length > 0) {
                        const avatarConfig = avatarConfigs[0]

                        dispatch(setAvatarConfigs(avatarConfigs))
                        dispatch(setCurrentAvatarConfigAndGetSuggestedTopics(avatarConfigs[0]))

                        navigate('chat')

                        amplitude.track('Session start', {
                            'avatar_name': avatarConfig.name,
                            'avatar_id': avatarConfig.id,
                            'is_dlai': true
                        })

                    } else {
                        customAlert('no andrew')
                    }
                })
                .catch((err: any) => {
                    alert(err)
                })
        }
    }, [canFetchAvatar])

    return <></>
}

export default DLAIAuth